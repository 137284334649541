import type { PaymentMethod } from "../../../types/PaymentMethod";
import netgiro from "../assets/netgiro.png";
import aur from "../assets/aur.png";
import { AvailableShippingMethod } from "../../../types/ShippingMethod";

export const NOLA_CLUB_ID = "RUKmA8";
export const FREE_SHIPPING_AMOUNT = 20000;
export const LIMIT_TO_OFFER_FREE_SHIPPING = 10000;
export const DROP_HOME_DELIVERY_LOCATION_ID =
  "9ec1f30c-2564-4b73-8954-25b7b3186ed3";

export const paymentMethods: Array<PaymentMethod> = [
  { id: "card", title: "Greiðslukort", externalId: 100 }, //TODO: 99 for DEbet, 100 for Credit
  { id: "aur", title: "Aur", logo: aur, externalId: 52 },
  { id: "netgiro", title: "Netgíró", logo: netgiro, externalId: 102 },
];

const capitalAreaZipcodes = [
  "101",
  "102",
  "103",
  "104",
  "105",
  "107",
  "108",
  "109",
  "110",
  "111",
  "112",
  "113",
  "116",
  "121",
  "123",
  "124",
  "125",
  "127",
  "128",
  "129",
  "130",
  "132",
  "150",
  "155",
  "161",
  "162",
  "170",
  "172",
  "200",
  "201",
  "202",
  "203",
  "206",
  "210",
  "212",
  "220",
  "221",
  "222",
  "225",
  "270",
  "271",
  "276",
];

export const filterShippingMethodsByZip = (
  shippingMethods: AvailableShippingMethod[],
  zip?: string,
) => {
  if (zip === undefined || capitalAreaZipcodes.includes(zip)) {
    return shippingMethods.filter((method) => method.id !== "droppHome");
  } else {
    return shippingMethods.filter(
      (method) => method.id !== "droppHomeCapitalArea",
    );
  }
};

export const boxSizes = {
  small: {
    length: "20",
    height: "10",
    width: "10",
  },
  medium: {
    length: "25",
    height: "10",
    width: "16",
  },
  large: {
    length: "11",
    height: "32",
    width: "20",
  },
};
